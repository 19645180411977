//== Nagivagion

.header {
}

/* logo */
.logo {
    width: 110px;
    margin-top: 0px;
}

.biglogo {
    //min-width: 100px;
    padding: 0px;
    max-width: 100%;
}

.logobox {
    display: table-cell;
    vertical-align: middle;
}

/* navbar */
.bg-navbar {
    background-color: $brand-secondary;
    //border-bottom: 2px solid $brand-primary;
}

// remove any gap in navbar
.navbar {
    padding-bottom: 0px;
    padding-top: 0px;
}

// set the height of the navbar
.navbar-custom {
    height: 65px;
}

.nav-link a {
    color: $brand-primary;
    border-radius: 0px;
    //background-color: transparent;
}

.nav-link > a:hover {
    color: $link-color-navlink;
    text-decoration: none;
    //background-color: transparent;
}

.navbar-nav > .active > a {
    color: $link-color-navlink;
}

.nav-pills {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border-radius: 0px;
    height: 100%;
}

.nav-pills .nav-link.active {
    background-color: rgb(0, 60, 94);
    /* rgba(79, 195, 247,0.4); */
    border-radius: 0px;
    //border-bottom: 2px solid $link-color-navlink;
}

.nav-pills > li {
    margin: 0px;
    padding: 0px;
    border: 0px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.navbar > .container .navbar-brand {
    margin-left: 0px;
}

.navbar-nav > li > a {
    margin: 0px;
    padding: 0px 6px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 145%;
    font-weight: 400;
}

.navbar-brand {
    margin: 0px;
    padding: 0px;
}

.navbar-tex,
.navbar-nav {
    @include type-size(1);
    font-weight: 400;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 83, 13,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
    border-color: $brand-primary;
}

.custom-toggler .navbar-toggler-icon:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 155, 245)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler:hover {
    border-color: $link-color-navlink;
    background-color: $white;
}

// prevent brand-logo to go below line

@media (max-width: 991px) {
    .navbar-default,
    .navbar-custom {
        height: 65px;
    }
    .navbar-default .navbar-nav > li > a,
    .navbar-brand {
        line-height: 145%;
    }
}

@media (max-width: 481px) {
    .navbar-default,
    .navbar-custom {
        height: 65px;
    }
    .navbar-default .navbar-nav > li > a,
    .navbar-brand {
        line-height: 145%;
    }
}
