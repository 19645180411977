// == TYPOGRAPHY

html {
    font-size: $font-size-base;
}

body {
    background-color: white;
    color: $text-color-dark;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 1.55rem;
}

p {
    font-size: 1rem;
    margin-bottom: 1.3rem;
    margin-left: 0px;
    line-height: $line-height-base * 1.125rem;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    color: $link-color;
    //background-color: transparent;
}

@mixin type-size($value: 1) {
    font-size: $ratio-type * $value * 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $brand-secondary;
    font-weight: inherit;
    margin: $ratio-type * 1rem 0 $ratio-type * 5rem;
    line-height: $line-height-base * 1em;
}

h1 {
    margin-top: 0;
    font-size: $ratio-type * 2.5em;
    line-height: $line-height-base * 0.75em;
    font-weight: 600;
}

h2 {
    @include type-size(1.75);
    font-weight: 500;
    line-height: $line-height-base * 1em;
    margin-top: 0;
    margin-bottom: 0;
}
h3 {
    @include type-size(1.5);
}
h4 {
    @include type-size(1.25);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0px;
}
h5 {
    @include type-size(1.125);
}
h6 {
    @include type-size(0.75);
    font-weight: 700;
    &:first-child {
        margin-top: 0px;
    }
}
small,
.font_small {
    @include type-size(0.75);
}

.subhead {
}

/* Typekit */
.wf-loading {
    visibility: hidden;
}
.wf-active {
    visibility: visible;
}

/* Headings */

.bigtitle {
    @include type-size(3);
    line-height: 55px;
    font-weight: 100;
}

.title {
    @include type-size(2.6);
    line-height: 40px;
}

.bighead {
    @include type-size(2.2);
    line-height: 36px;
}

.head {
    @include type-size(1.25);
    line-height: $line-height-base * 1.45rem;
    margin-left: 10px;
}

.news-author {
    font-style: italic;
}

.smallhead p {
    @include type-size(0.95);
    line-height: $line-height-base * 1.45rem;
    position: relative;
}

.subhead {
    margin-top: $gap * 2;
}

.intro-text {
    @include type-size(1.45);
    line-height: $line-height-base * 1.85rem;
    margin-bottom: $gap * 2;
    font-weight: 500;
}

.note-container {
    //margin-bottom: $gap *2;
    display: block;
    min-height: 144px;
}

.note {
    @include type-size(1);
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    line-height: $line-height-base * 1.125rem;
}

.team-note {
    @include type-size(1);
    //margin-bottom: -50px;
    margin-left: 10px;
    line-height: $line-height-base * 1.125rem;
}

.smallnote {
    display: inline-block;
    //margin-top: -00px;
    font-size: 0.85rem;
    line-height: $line-height-base * 0.75rem;
    margin-left: 0px;
    //line-height: $line-height-base * 75rem;
}
