// == Media

.media {
  margin: 0 0 $gap * 3 0;

  .media-heading {
    font-size: 1.05rem;
    line-height: $line-height-base * 1em;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-weight: 300;
    h2 {
      margin-top: 0.85rem;
    }
    h2 + h4 {
      margin-top: 0px;
    }
    h4 {
      margin-top: 0.85rem;
    }
  }
}

.picture-link {
  border-radius: 50%;
  background-color: $brand-secondary;
}

.profile-picture {
  border-radius: 50%;
  &.team-gallery-pic {
    mix-blend-mode: luminosity;
    &:hover {
      mix-blend-mode: normal;
    }
  }
}
