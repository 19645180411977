// Main Less
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,400i,500,600,700");
@import "bootstrap/bootstrap";
$fa-font-path: "font";
@import "font-awesome/font-awesome";
@import "font-awesome/academicons";
//@import "/css/solarized";
@import "katex";
@import "variables";
@import "typography";
@import "navigation";
@import "media";
@import "footer";
//videos
@import "video-embed";

.main {
  margin-top: $gap * 5;
}

// 20px gutter
.reduced-gutter.row {
  margin-right: -10px;
  margin-left: -10px;
}
.reduced-gutter {
  padding-right: 10px;
  padding-left: 10px;
}

/* General */

.container {
  padding-left: 25px;
  padding-right: 25px;
}

.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-gray {
  color: $light-grey;
}

.text-dark-gray {
  color: $gray-700;
}

/* other */

.hugespacer {
  height: 50px;
}

.bigspacer {
  height: 20px;
}

.spacer {
  height: 10px;
}

.smallspacer {
  height: 5px;
}

a.off {
  color: inherit;
}

pre {
  overflow-x: auto;
  code {
    overflow-wrap: normal;
    white-space: pre;
  }
}

/*
table {
  $include table();
  $include table-striped();
}


img {
  $include img-responsive;
}
*/

img.offset {
  float: right;
  margin: 0 0 10px 15px;
}

img.avatar {
  width: 20px;
}

.col-img {
  max-height: 257px;
  overflow: hidden;
  margin-bottom: $gap * 8;
}

.post img {
  float: none;
  margin: auto 10px;
}

.post iframe {
  padding-top: 10px;
  padding-bottom: 20px;
}

.post-list {
  margin-top: $gap * 3;
}

blockquote {
  //$include text-gray;
  p {
    font-size: ($font-size-base * 1);
  }
}

.breadcrumb {
  padding: 0;
  background-color: $body-bg;
  > li {
    & + li:before {
      padding: 0;
      content: "\00a0${breadcrumb-separator}\00a0";
    }
    & + li:after {
      padding: 0;
    }
  }
}

.feedbox-container {
  margin-top: $gap * 4;
  margin-bottom: $gap * 3;
}

.feedbox {
  height: 320px;
  overflow: hidden;
  -webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  margin-bottom: $gap;
  -webkit-box-shadow: inset 0 -30px 10px -10px white;
  -moz-box-shadow: inset 0 -30px 10px -10px white;
  box-shadow: inset 0 -30px 10px -10px white;
}

.feedboxblog {
  height: 180px;
  overflow: hidden;
  -webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  margin-bottom: $gap;
  /*	-webkit-box-shadow: inset 0 -30px 10px -10px white; 
  	   -moz-box-shadow: inset 0 -30px 10px -10px white; 
  	        box-shadow: inset 0 -30px 10px -10px white; */
}

@mixin square($value) {
  width: $value;
  height: $value;
}

.postbox {
  min-height: 80px;
}

.paperbox {
  min-height: 140px;
}

.page-title-paper {
  margin-bottom: $gap * 2;
}

.paper-img {
  @include square(210px);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.post {
  margin-top: $gap * 1;
}

.paper-img {
  margin-bottom: $gap * 1;
}

.projectbox {
  min-height: 120px;
}

.talkbox {
  min-height: 100px;
}

.titlebox {
  min-width: 300px;
}

.page-title {
  margin-bottom: $gap * 3;
}

.handlebox {
  height: 20px;
  display: table-cell;
  vertical-align: middle;
}

.pad-right {
  padding-right: 10px;
}

.pad-left {
  padding-left: 10px;
}

.pad-left-20 {
  padding-left: 20px;
}

.centered-pills {
  text-align: center;
  ul.nav-pills {
    display: inline;
  }
  li {
    display: inline;
  }
  a {
    float: left;
  }
}

.glyphbox {
  /* border-top: 2px solid $brand-primary; */
  margin-top: $gap * 2;
  padding-top: 39px;
  a {
    @include type-size(0.75);
  }
}

/* Infinite scroll */

.infinite-adjust-huener {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #333;
  margin: 60px auto;
  -webkit-animation: scaleout 1s infinite ease-in-out;
  animation: scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
// list

ul,
ol {
  padding-left: 17px;
  margin-bottom: 0 !important;
  li {
    padding-left: 10px;
  }
}

.mt30 {
  margin-top: $gap * 2;
}
.mt60 {
  margin-top: $gap * 2;
}

.sh_home p {
  column-count: 1;
  text-align: justify;
  //column-gap: 2em;
  initial-letter: 2;
  letter-spacing: -0.01em;
  font-size: 1rem;
  line-height: 1.65rem;
}

//@media screen and (min-width: $screen-sm-min) {
@media screen {
  .sh_home p {
    column-count: 2;
  }
}

.centered-pills {
  text-align: center;
}
.centered-pills ul.nav-pills {
  display: inline-block;
}
.centered-pills li {
  float: right;
  display: block;
  position: relative;
}
.centered-pills a {
  float: right;
  border-radius: 4px;
  padding: 10px 15px;
  text-decoration: none;
}
* html .centered-pills ul.nav-pills,
* + html .centered-pills ul.nav-pills {
  display: inline;
}

.centered-pills > li.active > a,
.centered-pills > li.active > a:hover,
.centered-pills > li.active > a:focus {
  //color: #ffffff;
  color: $lighter-grey;
  background-color: #00273d;
  text-decoration: none;
}
